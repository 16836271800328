<template>
  <div>
    <el-dialog title="肠道准备详情" @close="closeFn" :visible.sync="open" :close-on-click-modal="true" width="60%">
      <template #default>
        <div class="bowel-box">
          <div class="left-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <ul class="left">
                <template v-for="(item, index) in list">
                  <li :key="index" :class="{ 'is_active': active_id === item.defecatId }" @click="tab(item.defecatId)">
                    <p>{{ item.createTime }}</p>

                    <p
                      :class="{ 'success': item.status === '1' || item.status === '3', 'fail': item.status === '0', 'confirm': item.status === '2' }">
                      {{ status_name(item.status) }}</p>
                  </li>
                </template>
              </ul>
            </el-scrollbar>
          </div>

          <div class="right-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <div class="right">
                <el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="status === '1'" size="small"
                  label-width="100px">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="患者昵称">
                        <p>{{ form.nickname }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="手机号">
                        <p>{{ form.phone }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <p class="placeholder-p"></p>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="患者姓名">
                        <p>{{ form.patientName }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="患者性别">
                        <p>{{ gender_name(form.gender) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="患者年龄">
                        <p>{{ form.age }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="上传时间">
                        <p>{{ form.uploadTime }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="排便设施">
                        <p>{{ defecateFacility_name(form.defecateFacility) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <p class="placeholder-p"></p>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="预约单">
                        <el-image @click="seeImage(0)" :src="form.inspectPath" :fit="fit">
                          <template #error>
                            <div class="el-image__error">
                              <span>图片未上传</span>
                            </div>
                          </template>
                        </el-image>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="排便照片">
                        <el-image @click="seeImage(1)" :src="form.toiletPicPath" :fit="fit"></el-image>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="24">
                      <el-form-item label="排便图片">
                        <el-image @click="seeImage(2)" :src="form.cleanPicPath" :fit="fit"></el-image>
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="24">
                      <el-form-item :label="status == '2' ? 'AI分析结果' : status == '3' ? 'AI分析结果' : '本次结果'" prop="result">
                        <template v-if="status === '0' || status === '2'">
                          <el-select v-model="form.result" clearable placeholder="请选择">
                            <el-option v-for="item_ in result_list" :key="item_.key" :label="item_.value"
                              :value="item_.key"></el-option>
                          </el-select>
                        </template>
                        <template v-else-if="status === '1' || status === '3'">
                          <p>{{ result_name(form.result) }}</p>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="备注" prop="remark">
                        <template v-if="status === '0' || status === '2'">
                          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入"
                            maxlength="100" show-word-limit clearable v-model="form.remark">
                          </el-input>
                        </template>
                        <template v-else-if="status === '1' || status === '3'">
                          <p>{{ form.remark }}</p>
                        </template>
                      </el-form-item>
                    </el-col>
                    <template v-if="status === '0' || status === '2'">
                      <el-col :span="24">
                        <el-form-item>
                          <div class="but">
                            <el-button type="primary" size="small" @click="onSubmit">发送回复</el-button>
                          </div>
                        </el-form-item>
                      </el-col>
                    </template>
                  </el-row>
                </el-form>
              </div>
            </el-scrollbar>
          </div>

        </div>
      </template>
    </el-dialog>
    <el-image-viewer v-if="image_viewer_data.show" :on-close="closeImgViewer" :z-index="9999"
      :url-list="image_viewer_data.list" />
  </div>
</template>

<script>
export default {
  name: "BowelDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bowlId: {
      type: String,
      default: () => ""
    },
    defecatId: {
      type: String,
      default: () => ""
    },
  },
  data() {
    return {
      list: [],
      active_id: "",
      form: {},
      rules: {
        result: [
          { required: true, message: '请选择结果', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ]
      },
      status: "",

      patient_status: [
        {
          key: "0",
          value: "待回复",
        },
        {
          key: "1",
          value: "已完成",
        },
        {
          key: "2",
          value: "待确认",
        },
        {
          key: "3",
          value: "已完成",
        }
      ],
      result_list: [
        {
          key: "0",
          value: "未识别",
        },
        {
          key: "1",
          value: "很好",
        },
        {
          key: "2",
          value: "较好",
        },
        {
          key: "3",
          value: "很差",
        },
        {
          key: "4",
          value: "较差",
        },
      ],

      fit: "cover",
      image_viewer_data: {
        show: "",
        zIndex: 9999,
        list_: [],
        list: [],
      },

      defecation_facilities_list: [
        {
          key: '0',
          value: '坐便器',
        },
        {
          key: '1',
          value: '蹲便器',
        }
      ],

      gender_arr: [
        {
          key: '0',
          value: '女'
        },
        {
          key: '1',
          value: '男'
        },
      ]

    }
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      },
    },
    status_name() {
      return function (key) {
        let str;
        str = this.patient_status.filter(item => item.key === key).map(item => item.value)[0];
        return str;
      }
    },
    result_name() {
      return function (key) {
        let str;
        str = this.result_list.filter(item => item.key === key).map(item => item.value)[0];
        return str;
      }
    },
    defecateFacility_name() {
      return function (key) {
        let str;
        str = this.defecation_facilities_list.filter(item => item.key === key).map(item => item.value)[0];
        return str;
      }
    },
    gender_name() {
      return function (key) {
        let str;
        str = this.gender_arr.filter(item => item.key === key).map(item => item.value)[0];
        return str;
      }
    },
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.getData(this.bowlId);
          this.active_id = this.defecatId;
        }
      },
      immediate: true,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    closeFn() {
      this.$emit("close");
    },
    getData(bowlId) {
      this.$get(`colonreply/detail/${bowlId}`)
        .then(res => {
          console.log(res);
          if (!res) return false;
          let data = res.data;
          this.list = data;
          this.form = (data || []).filter(item => item.defecatId === this.defecatId)[0].patientDetailVo;
          this.status = (data || []).filter(item => item.defecatId === this.defecatId)[0].status;
          this.image_viewer_data.list_ = this.form.inspectPath ? [this.form.inspectPath, this.form.toiletPicPath, this.form.cleanPicPath] : [this.form.toiletPicPath, this.form.cleanPicPath];
        })
    },
    seeImage(index) {
      let arr = this.image_viewer_data.list_.slice(index, this.image_viewer_data.list_.length),
        arr_ = this.image_viewer_data.list_.slice(0, index);
      this.image_viewer_data.list = arr.concat(arr_);
      this.image_viewer_data.show = true;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let obj = {
            bowlPrepareId: this.form.bowlPrepareId,
            defecatPicInfoId: this.form.defecatPicInfoId,
            docOpenId: this.form.docOpenId,
            patientOpenId: this.form.patientOpenId,
            remark: this.form.remark,
            result: this.form.result,
          }

          this.$post("colonreply/submitBowl", obj)
            .then(res => {
              if (!res) return false;
              // eslint-disable-next-line no-unused-vars
              let data = res.data;
              this.getData(this.bowlId);
              this.$message({
                message: '操作成功',
                type: 'success'
              });
            })
        }
      })
    },
    tab(id) {
      this.active_id = id;
      this.form = (this.list || []).filter(item => item.defecatId === id)[0].patientDetailVo;
      this.status = (this.list || []).filter(item => item.defecatId === id)[0].status;
      this.image_viewer_data.list_ = this.form.inspectPath ? [this.form.inspectPath, this.form.toiletPicPath, this.form.cleanPicPath] : [this.form.toiletPicPath, this.form.cleanPicPath];
    },
    closeImgViewer() {
      this.image_viewer_data.show = false;
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  background: #1A7CF2;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .el-dialog__title {
    color: #FFFFFF;
  }

  .el-dialog__headerbtn {
    top: 14px;

    .el-icon {
      color: #FFFFFF;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog {
  border-radius: 10px !important;
}

/deep/ .scrollbar-wrapper {
  margin-bottom: 0 !important;
}

.bowel-box {
  display: flex;

  .left-box {
    width: 255px;
    max-height: 600px;
    box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.1);

    .left {
      width: 100%;

      //padding: 40px 20px;
      li {
        width: 100%;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: #DCDCDC;
        //border: 1px solid #DCDCDC;
        padding: 0 12px;
        //border-radius: 4px;
        cursor: pointer;
      }

      li+li {
        //margin-top: 20px;
      }

      .is_active {
        background-color: #1A7CF2;
        color: #FFFFFF;
        border-color: #1A7CF2;
      }
    }
  }

  .right-box {
    width: calc(100% - 255px);
    max-height: 600px;
    position: relative;

    .right {
      flex: 1;
      padding: 16px 16px 16px 0;
    }
  }

  .but {
    display: flex;
    justify-content: flex-end;
  }
}

.el-image {
  width: 80%;
  height: 300px;
  cursor: pointer;
}

/deep/.el-form-item {
  //margin-bottom: 0!important;
}

.placeholder-p {
  height: 32px;
  line-height: 32px;
  margin-bottom: 18px;
}

.success {
  color: #63A103;
}

.fail {
  color: #D9001B;
}

.confirm {
  color: rgb(245, 145, 35);
}
</style>
